import NavigationBar from '@/components/NavigationBar.vue'; // @ is an alias to /src
import FooterBox from '@/components/FooterBox.vue';

export default {
  name: "app",
  components: {
    NavigationBar,
    FooterBox
  },
};
