import { defineComponent } from 'vue';

export default defineComponent ({
  name: "navigationBar",
  data(){
    return {
      isActive: false
    }
  },
  methods : {
    scrollNav() {
      let prevScrollpos = window.pageYOffset;
      console.log(window.pageYOffset)
      window.onscroll = () => {
      const currentScrollPos = window.pageYOffset;
        if (prevScrollpos > currentScrollPos) {
          document.getElementById("nav")!.style.top = "0"; //Css style is not working as intendent
        } else {
          document.getElementById("nav")!.style.top = "-12vh" ;
        }
        prevScrollpos = currentScrollPos;
      }
    },
    burger(){
      this.isActive = !this.isActive;
      //const x = document.getElementById("burgerMenu")!;
      const body = document.body;
      if (this.isActive == true) {
        document.getElementById("burgerBox")!.style.display = "flex"; //Css style is not working as intendent
        body.style.overflowY = 'hidden';
      } else {
        document.getElementById("burgerBox")!.style.display = "none";
        body.style.overflowY = 'auto';
      }
    }
  },
  mounted() {
    this.scrollNav();
  }
});
