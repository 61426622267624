import { createPrismic } from "@prismicio/vue";
import { linkResolver } from "../link-resolver";

const prismic = createPrismic({
  endpoint: "https://timarophotography.prismic.io/api/v2",
  linkResolver,
});

export default prismic;

// import { createApp } from "vue";
// import PrismicVue from "@prismicio/vue.vue";
// import App from "./App.vue";
// import { linkResolver } from "../link-resolver";
//
// createApp(App).use(PrismicVue as any, {
//   endpoint: "https://timarophotography.prismic.io/api/v2",
//   apiOptions: " { accessToken }",
//   linkResolver,
// }).mount('#app')
