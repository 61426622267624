import { defineComponent } from 'vue';
import GetImage from '@/network/GetImage';

export default defineComponent ({
  name: "galleryImages",
  data (){
    return {
      content : {},
      index: 0,
      image: ''
    };
  },
  props: {
    prismicType:{
      type : String,
      default : null
    },
    size:{
      type : String,
      default : null
    }
  },
  methods: {
    async startLoad(){
      this.content = await GetImage.getImages(this.prismicType)
      console.log(this.content)
    },
    imgPreview(id: string, image: string, index: number){
        document.getElementById(id)!.style.display = "flex";
        this.image = image;
        this.index = index;
    },
    closeImgPreview(id: string){
        document.getElementById(id)!.style.display = "none";
    },
    lastImgPreview(content: any){
      if (this.index === 0){
        this.image = content[content.length - 1].data.img.url
        this.index = content.length - 1;
      }else{
        this.image = content[this.index - 1].data.img.url
        this.index = this.index - 1;
      }
    },
    nextImgPreview(content: any){
      const length = content.length - 1
      if (this.index < length){
        this.image = content[this.index + 1].data.img.url
        this.index = this.index + 1;
      }else{
        this.image = content[0].data.img.url
        this.index = 0;
      }
    },
    key(direction: any){
      this.nextImgPreview(direction)
    }
  },
  created(){
    this.startLoad()
  }
});
