import { defineComponent } from 'vue';
import GetImage from '@/network/GetImage';
import GalleryImages from '@/components/GalleryImages.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    GalleryImages,
  },
  data (){
    return {
      image: {}
    };
  },
  methods: {
    scrollTo(category: any){
      document.getElementById(category)!.scrollIntoView({behavior: "smooth"})
    },
  },
  async created(){
    const data = await GetImage.getImages('home');
    this.image = data[0].data.img.url
  },
});
