import prismic from '@/prismic/PrismicConfig'

export default {
  async getImages(category: any){
    const data = await prismic.client.getAllByType(category, {
      orderings: {
        field: 'document.first_publication_date',
      },
    });
    return data
  },
}
